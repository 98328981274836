import { SetStateAction } from "react";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { useDeleteUserMutation, useGetUserQuery, useSwitchUserActiveMutation, useUndeleteUserMutation } from "../../../api/userApi";
import { Box, Divider, Grid2 as Grid, Stack } from "@mui/material";
import Avatar from "../../Avatar/Avatar";
import DetailsHeader from "../../CloudMember/DetailsPanel/DetailsHeader";
import { t } from 'i18next';
import { useAppSelector } from "../../../store";
import { selectId, selectIsClinicAdmin, selectIsClinicUser, selectIsSystemaAdmin, UserRole } from "../../../store/authSlice";
import { Trans } from "react-i18next";
import StyledText from "../../StyledTextField/StyledText";
import StyledLabel from "../../StyledTextField/StyledLabel";
import FooterText from "../../StyledTextField/FooterText";
import { dateString, roleName } from "../../ResultAssignment/fragments/SessionDetailsBox";

type DetailsProps = {
    userId: string;
    setPage: (value: SetStateAction<Pages>) => void;
};

const UserDetails = ({ setPage, userId }: DetailsProps) => {
    const { data, isLoading } = useGetUserQuery(userId);//, { refetchOnMountOrArgChange: true });
    const [switchActive] = useSwitchUserActiveMutation();
    const [undelete] = useUndeleteUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const ownId = useAppSelector(selectId);
    const isClinicUser = useAppSelector(selectIsClinicUser);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const name = `${data?.givenName} ${data?.sureName}`;

    if (isLoading) {
        return <div>loading...</div>;
    }

    const deleteEntry = () => {
        if (data?.deleted) {
            undelete(userId);
            return;
        }
        deleteUser(userId);
    };

    const getFullName = () => {
        return `${data?.givenName} ${data?.sureName}`.trim();
    };

    const getDeleteHandler = () => {
        if (ownId !== userId && !isClinicUser) {
            return deleteEntry;
        }
    };

    const canEditUser = () => {
        if (isSystemAdmin || ownId === userId) {
            return true;
        }
        if (isClinicAdmin && data?.role === UserRole.ClinicUser) {
            return true;
        }
        return false;
    };
    return (
        <Box sx={{ height: '100%', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px' }} >
            <Stack direction="column" sx={{ height: '100%', overflow: 'auto' }} >

                <Stack sx={{ display: 'flex', alignContent: 'center', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="row">
                    <div style={{ marginRight: '20px' }}>
                        <Avatar size="large" name={name} />
                    </div>
                    <DetailsHeader
                        memberType={t('member-type-user')}
                        isActive={Boolean(data?.active)}
                        isDeleted={Boolean(data?.deleted)}
                        name={name ?? ''}
                        editEntry={canEditUser() ? () => setPage(Pages.EDIT) : undefined}
                        switchActive={ownId === userId || isClinicUser ? undefined : () => switchActive(userId)}
                        deleteEntry={getDeleteHandler()}
                        deleteEntryMessage={<Trans i18nKey={'delete-user-message'} values={{ name: getFullName() }} />}
                        undeleteEntryMessage={<Trans i18nKey={'undelete-user-message'} values={{ name: getFullName() }} />}
                    />
                </Stack >

                <Divider />

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledText>{data?.comment}</StyledText>
                </Stack>

                <Divider />

                <Stack pt="20px" direction="row" spacing={2}>
                    <StyledLabel sx={{ width: '50%' }}>{t('cloud-member-form-sure-name-label')}</StyledLabel>
                    <StyledLabel>{t('cloud-member-form-given-name-label')}</StyledLabel>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <StyledText sx={{ width: '50%' }}>{data?.sureName}</StyledText>
                    <StyledText>{data?.givenName}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-username-label')}</StyledLabel>
                    <StyledText>{data?.username}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-email-label')}</StyledLabel>
                    <StyledText>{data?.email}</StyledText>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-role-label')}</StyledLabel>
                    <StyledText>{data?.role ? roleName(data?.role) : ''}</StyledText>
                </Stack>

                <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
                    <Divider sx={{ marginBottom: '15px' }} />
                    <Grid container>
                        <Grid size={6}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                        </Grid>
                        <Grid size={6} sx={{ textAlign: 'right' }}>
                            <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                        </Grid>
                        <Grid size={6}>
                            <FooterText>{dateString(data?.lastUpdated ?? data?.created)}</FooterText>
                        </Grid>
                        <Grid size={6} sx={{ textAlign: 'right' }}>
                            <FooterText>{dateString(data?.created)}</FooterText>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Box>
    );
};

export default UserDetails;
