import { useDeleteTherapySessionMutation, useUndeleteTherapySessionMutation, useUpdateTherapySessionDataMutation } from "../../../api/therapySessionsApi";
import { DeviceMessage, MappedTherapySessionData } from "../../../types/types";
import SessionDetailsBox, { BoxType, iso8601ToDate } from "./SessionDetailsBox";
import { DateRangePicker } from "rsuite";
import { addDays } from 'date-fns';
import { useState } from "react";
import { DateRange } from "rsuite/esm/DateRangePicker";
import AssignDialog from "./AssignDialog";
import ConfirmDialog from "../../CloudMember/ConfirmDialog/ConfirmDialog";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import theme, { smallFont } from "../../../assets/theme";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { addHistoryAction, clearHistory, selectHistoryAction } from "../../../store/historyState";
import NoSessionFoundMessage from "../../Patients/fragments/NoSessionFoundMessage";
import UndoIcon from "../../../icons/UndoIcon";
import useFetchAssignedSessions from "../../../hooks/useFetchAssignedSessions";

const StyledUndoButton = styled(Button)(({ theme }) => ({
    minWidth: 'min-content',
    borderColor: theme.palette.lightgreen.main,
    backgroundColor: theme.palette.lightgreen.main,
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    fontSize: smallFont,
    padding: '20px 10px 20px 10px',
    "&:hover": {
        backgroundColor: theme.palette.lightgreen.main,
    },
    "&:active": {
        backgroundColor: theme.palette.lightgreen.main,
    },
    '& .MuiButton-startIcon': {
        '& > *:nth-of-type(1)': {
            fontSize: smallFont,
        },
    },
}));

export interface Props {
    patientId: string;
    devices: DeviceMessage[];
    unassignedSessions: MappedTherapySessionData[];
}

const UnAssignedList = ({ unassignedSessions, devices, patientId }: Props) => {
    const [dateRange, setDateRange] = useState<[Date, Date] | undefined>([new Date(), new Date()]);
    const [sessionToAssign, setSessionToAssign] = useState<MappedTherapySessionData | undefined>();
    const [sessionToDelete, setSessionToDelete] = useState<MappedTherapySessionData | undefined>();
    const [deleteTherapySession] = useDeleteTherapySessionMutation();
    const [updateThreapySession] = useUpdateTherapySessionDataMutation();
    const [undeleteTherapySession] = useUndeleteTherapySessionMutation();
    const { isAssignedSessionsLoading, assignedSessions } = useFetchAssignedSessions(patientId);

    const history = useAppSelector(selectHistoryAction);
    const dispatch = useAppDispatch();

    if (isAssignedSessionsLoading) {
        return <></>;
    }

    const sortedData = (data?: MappedTherapySessionData[]) => {
        if (!data) {
            return [];
        }
        return data.slice().sort((a, b) => {
            if (a.exerciseStartDate > b.exerciseStartDate) {
                return -1;
            }
            if (a.exerciseStartDate < b.exerciseStartDate) {
                return 1;
            }
            return 0;
        });
    };

    const deleteSession = (session: MappedTherapySessionData) => {
        deleteTherapySession(session.id).then(() => {
            setSessionToDelete(() => undefined);
            dispatch(addHistoryAction({ sessionId: session.id, action: 'delete' }));
        });
    };

    const assignSession = (session: MappedTherapySessionData) => {
        setSessionToAssign(() => session);
    };

    const selectDateRange = (e: DateRange | null) => {
        if (e) {
            setDateRange(() => [e[0], e[1]]);
            return;
        }
        setDateRange(() => undefined);
    };

    const getFilteredData = () => {
        if (!unassignedSessions) {
            return [];
        }
        if (dateRange) {
            if (dateRange[0].getFullYear() === 1980) {
                // first + last therapy session
                if (unassignedSessions.length > 1) {
                    return [unassignedSessions[0], unassignedSessions[unassignedSessions.length - 1]];
                }
                // only first session
                return [unassignedSessions[0]];
            }
            return unassignedSessions.filter((session) => {
                const start = iso8601ToDate(session.exerciseStartDate);
                start.setHours(0, 0, 0, 0);
                dateRange[0].setHours(0, 0, 0, 0);
                dateRange[1].setHours(0, 0, 0, 0);
                if (start.getTime() >= dateRange[0].getTime() && start.getTime() <= dateRange[1].getTime()) {
                    return session;
                }
                return undefined;
            });
        }
        return unassignedSessions;
    };

    const getSessionBoxes = () => {
        const sessions = sortedData(getFilteredData()).map((session: MappedTherapySessionData) => {
            const device = devices.find((device) => device.serial === session.serial);
            return <SessionDetailsBox label={device?.name ?? session.type} boxType={BoxType.UNASSIGNED} editSession={() => { }} deleteSession={() => { setSessionToDelete(session); }}
                onArrowClick={() => assignSession(session)} key={session.id} session={session} />;
        }
        );
        if (sessions.length > 0) {
            return sessions;
        }
        return <NoSessionFoundMessage showBorder={false} />;
    };

    const unassignSession = (sessionId: string) => {
        const request = {
            sessionId,
            patientId: '',
            comment: '',
            weightRelief: '',
            perceptionOfLoad: '',
            handrailHeight: '',
            beltSize: '',

        };

        updateThreapySession(request).then((result) => {
        });

    };

    const undo = () => {
        if (history) {
            switch (history.action) {
                case 'delete':
                    undeleteTherapySession(history.sessionId);
                    dispatch(clearHistory());
                    break;
                case 'assign':
                    if (history.patientId && history.sessionId) {
                        unassignSession(history.sessionId);
                        dispatch(clearHistory());
                    }
                    break;
                case 'unassign':
                    break;
            }
        }
    };

    const renderDatepickerValue = () => {
        if (dateRange?.[1].getFullYear() === 1980) {
            return 'Erste + Letzte Trainingseinheit';
        }
        return '';
    };

    const onSelect = (e: Date) => {
        setDateRange(() => [e, e]);
    };

    return (
        <Stack direction="column" sx={{
            height: 'calc(100% - 110px)',
            overflow: 'auto',
            width: '100%',
            marginTop: '20px',
            paddingRight: '10px',
        }}>

            <DateRangePicker
                onSelect={onSelect}
                onChange={selectDateRange}
                showOneCalendar
                character=" - "
                size="lg"
                editable={false}
                format="dd.MM.yyyy"
                placeholder={t('calendar-placeholder-label')}
                renderValue={() => { return renderDatepickerValue(); }}
                defaultValue={dateRange}
                ranges={[
                    {
                        label: t('calendar-today-range-label'),
                        value: [new Date(), new Date()],
                        placement: 'left'
                    },
                    {
                        label: t('calendar-7-day-range-label'),
                        value: [addDays(new Date(), -7), new Date()],
                        placement: 'left'
                    },
                    {
                        label: t('calendar-14-day-range-label'),
                        value: [addDays(new Date(), -14), new Date()],
                        placement: 'left'
                    },
                ]} />

            <Stack direction="column" sx={{ height: '100%', overflow: 'auto', width: '100%', marginTop: '10px', paddingRight: '10px' }}>
                {getSessionBoxes()}
            </Stack>

            <Box sx={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
                <StyledUndoButton disabled={history === undefined} onClick={() => undo()}>
                    <UndoIcon sx={{ marginLeft: '0px', marginRight: '10px' }} stroke={history ? theme.palette.primary.main : theme.palette.inactive.main} />
                    <Typography sx={{ textAlign: 'left', paddingRight: '10px' }}>{t('back-button-label')}</Typography>
                </StyledUndoButton>
            </Box >

            {sessionToAssign && <AssignDialog assignedSessions={assignedSessions} onClose={() => { setSessionToAssign(undefined); }} patientId={patientId} session={sessionToAssign} />}
            {sessionToDelete && <ConfirmDialog title={t('delete-dialog-title')} isVisible={true} message={t('delete-therapy-session-message')} okCallback={() => deleteSession(sessionToDelete)}
                cancelCallback={() => { setSessionToDelete(undefined); }} />}
        </Stack>
    );
};

export default UnAssignedList;
