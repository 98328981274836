import { memo, useEffect, useState } from 'react';
import ClinicDetails from './fragments/ClinicDetails';
import TwoColumns from '../PageTemplates/TwoColumns';
import EditClinic from './fragments/EditClinic';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIsRetailerAdmin } from '../../store/authSlice';
import ListPanelButtonBar from '../CloudMember/MemberListPanels/ListPanelButtonBar';
import { Pages } from '../CloudMember/MemberListPanels/listPanelGlobals';
import AddCloudMember from '../CloudMember/DetailsPanel/AddCloudMember';
import CloudMemberList from '../CloudMember/MemberListPanels/CloudMemberList';
import { t } from 'i18next';
import { resetFilter } from '../../store/filterState';
import { useAddClinicMutation, useGetClinicListQuery } from '../../api/clinicApi';
import { resetListState } from '../../store/listStateSlice';

const MemoCloudMemberList = memo(CloudMemberList);

const Clinic = () => {
    const [clinicId, setClinicId] = useState("");
    const [page, setPage] = useState(Pages.DETAILS);
    const [addClinic] = useAddClinicMutation();
    const { isLoading, data } = useGetClinicListQuery(undefined, { refetchOnMountOrArgChange: true });

    const [scrollPos, setScrollPos] = useState(0);
    const dispatch = useAppDispatch();
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);


    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());

    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            const filtered = data.filter(entry => entry.active);
            if (filtered && filtered.length > 0) {
                if (clinicId && clinicId !== "") {
                    const selectedMember = data.find((entry) => entry.id === clinicId);
                    if (!selectedMember) {
                        showDetails(filtered[0].id, scrollPos);
                    }
                } else {
                    showDetails(filtered[0].id, scrollPos);
                }
            }
        }
    }, [isLoading, data, clinicId, scrollPos, dispatch]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setClinicId(() => id);
        setPage(Pages.DETAILS);
    };

    const addEntry = () => {
        setPage(Pages.ADD);
    };

    const getRightComponent = () => {
        switch (page) {
            case Pages.ADD:
                return (<AddCloudMember addMember={addClinic} setPage={setPage} />);
            case Pages.EDIT:
                return (<EditClinic setPage={setPage} clinicId={clinicId} />);
            case Pages.DETAILS:
                return (<>{clinicId && <ClinicDetails setPage={setPage} clinicId={clinicId} />}</>);
            default:
                if (clinicId) {
                    return (<ClinicDetails setPage={setPage} clinicId={clinicId} />);
                }
                return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
        }

    };

    return (
        <TwoColumns leftComponent={
            <>
                <ListPanelButtonBar
                    acceptText={t('accept-clinic-dialog-text')}
                    searchFieldPlaceholder={t('clinic-searchfield-placeholder')}
                    addEntryLabel={t('clinic-add-button')}
                    showAddButton={isRetailerAdmin}
                    showAcceptButton={isRetailerAdmin}
                    showInviteButton={false}
                    addNewEntry={addEntry} />
                <MemoCloudMemberList scrollPos={scrollPos} data={data} selectedId={clinicId} showDetails={showDetails} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default Clinic;
