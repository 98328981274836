import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { DeviceMessage, MappedTherapySessionData, Patient } from "../../../../types/types";
import { isEmpty } from "lodash";
import { getDeviceKey, getTleExerciseDuration, getTleTwoResultsString } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import useFetchAssignedSessions from "../../../../hooks/useFetchAssignedSessions";
import { useEffect, useRef, useState } from "react";
import NoSessionFoundMessage from "../NoSessionFoundMessage";
import { useAppSelector } from "../../../../store";
import { selectAccordionSessionId } from "../../../../store/uiState";
import { sortedSessionsByStartdate } from "../../../ResultAssignment/fragments/AssignedList";
import LyraIcon from "../../../../icons/LyraIcon";
import { largeFont, smallFont } from "../../../../assets/theme";
import ThreeDottMenuIcon from "../../../../icons/ThreeDottMenuIcon";
import { t } from "i18next";
import EditIcon from "../../../../icons/EditIcon";
import { PopoverEntry } from "../../../CloudMember/DetailsPanel/DetailsHeader";
import StyledText from "../../../StyledTextField/StyledText";
import EditDialog from "../../../ResultAssignment/fragments/EditDialog";

const getValueString = (value: string | number | undefined) => {
    if (isEmpty(value)) {
        return '-';
    }
    return value;
};

type Props = {
    patient: Patient;
    devices: DeviceMessage[];
};

const LastSessionData = ({ devices, patient }: Props) => {
    const perceptionValues: string[] = t('perception-of-load', { returnObjects: true });
    const { isAssignedSessionsLoading, assignedSessions } = useFetchAssignedSessions(patient.id);
    const [session, setSession] = useState<MappedTherapySessionData>();
    const deviceKey = useAppSelector(selectAccordionSessionId);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const dialogRef = useRef();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!isAssignedSessionsLoading) {
            const sessions = assignedSessions.filter(session => getDeviceKey(session) === deviceKey);
            if (sessions.length > 0) {
                setSession(_session => sortedSessionsByStartdate(sessions)[0]);
            } else {
                setSession(_session => undefined);
            }
        }
    }, [isAssignedSessionsLoading, assignedSessions, deviceKey]);

    if (isAssignedSessionsLoading) {
        return <div> loading...</div>;
    }
    if (!session) {
        return <NoSessionFoundMessage />;
    }

    const renderDropDown = () => {
        return (
            <Popover
                open={open}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    borderRadius: '10px',
                    userSelect: 'none'
                }}
            >
                <Stack direction="column" spacing={2} padding="10px">
                    <PopoverEntry onClick={() => { setShowEditDialog(true); setAnchorEl(undefined); }}><EditIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-edit')}</PopoverEntry>
                </Stack>
            </Popover>
        );

    };

    const getDeviceName = () => {
        const device = devices.filter(device => device.serial === session.serial);
        if (device.length === 1) {
            return device[0].name;
        }
        return device.length;
    };

    return (
        <Grid container size={12} sx={{ paddingLeft: '20px' }}>
            <Grid container fontWeight="bold" sx={{ width: '100%', height: '104px', paddingBottom: '10px' }}>
                <Stack direction="row" style={{ width: '100%' }}>
                    <Stack sx={{ justifyContent: 'center', }}>
                        <LyraIcon sx={{ fontSize: '50px' }} />
                    </Stack>
                    <Stack direction="column" sx={{ paddingLeft: '10px', justifyContent: 'center', width: '100%' }}>
                        <Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }}>{t('last-session-header-title')}</Typography>
                        <Typography sx={{ fontSize: largeFont }}>{getDeviceName()}</Typography>
                    </Stack>
                    <Stack alignItems="end" style={{ paddingRight: '10px', paddingTop: '20px', cursor: 'pointer' }}>
                        <Box ref={dialogRef} onClick={() => setAnchorEl(dialogRef.current)} style={{ display: 'flex', cursor: 'pointer' }}>
                            <ThreeDottMenuIcon />
                        </Box>
                    </Stack>
                </Stack>
            </Grid>

            <Grid container sx={{ paddingRight: '20px' }}>
                <Grid size={12}><Divider /></Grid>

                <Grid container size={12} sx={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Grid size={12} fontWeight="bold">{session.therapyMethod}</Grid>
                    <Grid size={12}><StyledText>{t('assign-dialog-trainings-goal')}: {session.trainingsGoal}</StyledText></Grid>
                </Grid>

                <Grid size={12}><Divider /></Grid>

                <Grid container size={12} sx={{ fontWeight: 'bold', paddingTop: '10px' }}>
                    <Grid size={6}>{session.tlResult7Label}</Grid>
                    <Grid size={6}>{session.tlResult8Label}</Grid>
                </Grid>

                <Grid size={6}>{getValueString(session.tlResult7)} {session.tlResult7 && session.tlResult7Unit}</Grid>
                <Grid size={6}>{session.tlResult8 && session.tlResult8Unit} {getValueString(session.tlResult8)}</Grid>

                <Grid container size={12} fontWeight="bold" pt="20px">
                    <Grid size={6}>{session.tlResult3Label}</Grid>
                    <Grid size={6}>{session.tlResult5Label}</Grid>
                </Grid>

                <Grid size={6}>{getTleTwoResultsString(session, 'tlResult3', 'tlResult4') ?? '-'}</Grid>
                <Grid size={6}>{getValueString(session.tlResult5)} {session.tlResult5 && session.tlResult5Unit}</Grid>

                <Grid container size={12} fontWeight="bold" pt="20px">
                    <Grid size={6}>{t('session-duration-label')}</Grid>
                    <Grid size={6}>{session.tlResult6Label}</Grid>
                </Grid>
                <Grid size={6}>{getTleExerciseDuration(session)}</Grid>
                <Grid size={6}>{perceptionValues[parseInt(session.tlResult6 ?? '4')]}</Grid>

                <Grid container size={12} fontWeight="bold" pt="20px">
                    <Grid size={6}>{session.tlResult2Label}</Grid>
                    <Grid size={6}>{t('session-comment-label')}</Grid>
                </Grid>
                <Grid size={6}>{getValueString(session.tlResult2)} {session.tlResult2 && session.tlResult2Unit}</Grid>
                <Grid size={6}>{getValueString(session.comment)}</Grid>
                {renderDropDown()}
                {showEditDialog && session && <EditDialog onClose={() => { setShowEditDialog(false); }} session={session} patientId={patient.id} />}
            </Grid>
        </Grid>
    );
};

export default LastSessionData;
