import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LyraIcon from '../../../../icons/LyraIcon';
import { Box, Typography } from '@mui/material';
import { largeFont } from '../../../../assets/theme';
import { DeviceMessage, Patient } from '../../../../types/types';
import { getDeviceKey, } from '../../../ResultAssignment/fragments/SessionDetailsBox';
import { selectAccordionSessionId } from '../../../../store/uiState';
import { useAppSelector } from '../../../../store';
import { usePinPatientToDeviceMutation } from '../../../../api/deviceApi';
import DeviceBox from './DeviceBox';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    '& .MuiAccordionSummary-root': {
        padding: '0px 0px 0px 0px',
    },
    '& .MuiAccordionDetails-root': {
        padding: '20px 0px 20px 0px',
        borderTop: `2px solid ${theme.palette.lightgreen.main}`,
        borderBottom: `2px solid ${theme.palette.lightgreen.main}`,
    },
    '&:first-of-type': {
        borderTop: `1px solid  ${theme.palette.inactive.main}`,
    },
    '&:not(:last-child)': {
        '& .MuiAccordionSummary-root': {
            '&:not(.Mui-expanded)': {
                borderBottom: `1px solid  ${theme.palette.inactive.main}`,
            }
        },
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
}));

type Props = {
    devices: DeviceMessage[];
    patients: Patient[];
    patient: Patient;
    name: string;
    handleChange: (panel: string) => void;
};

const DeviceTypeAccordion = ({ devices, patients, patient, name, handleChange }: Props) => {
    const accordionSessionId = useAppSelector(selectAccordionSessionId);
    const [pinPatientToDevice] = usePinPatientToDeviceMutation();

    const setClearPatient = (device: DeviceMessage) => {
        if (device.patientId) {
            if (device.patientId !== patient.id) {
                pinPatientToDevice({ deviceId: device.id, patientId: patient.id });
                return;
            }
            pinPatientToDevice({ deviceId: device.id });
            return;
        }
        pinPatientToDevice({ deviceId: device.id, patientId: patient.id });
    };

    return <Accordion key={name} expanded={accordionSessionId === name} onChange={() => handleChange(name)} >
        <AccordionSummary id={`${name}-header`}>
            <LyraIcon style={{ fontSize: '28pt' }} />
            <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }} fontSize={largeFont}>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {devices.filter(device => getDeviceKey(device) === name).map(device => <DeviceBox
                    key={device.id}
                    device={device}
                    setClearPatient={setClearPatient}
                    patient={patient}
                    patients={patients} />)}
            </Box>
        </AccordionDetails>
    </Accordion>;
};

export default DeviceTypeAccordion;
