import { Divider, Grid, Stack, TextField, styled } from "@mui/material";
import FooterText from "../../../StyledTextField/FooterText";
import { Patient } from "../../../../types/types";
import { t } from "i18next";
import TheraButton from "../../../TheraButtons/TheraButton";
import StyledTextField from "../../../StyledTextField/StyledTextField";
import { largeFont, mediumFont } from "../../../../assets/theme";
import { useRef } from "react";
import { useUpdatePatientMutation } from "../../../../api/patientApi";
import { isEmpty } from "lodash";
import { ErrorMessage } from "../../../CloudMember/DetailsPanel/AddCloudMember";
import { enqueueSnackbar } from "notistack";
import { dateString } from "../../../ResultAssignment/fragments/SessionDetailsBox";

const MultilineField = styled(TextField)(({ theme }) => ({
    padding: 0,
    borderRadius: '5px',
    borderColor: theme.palette.primary.light,
    width: '100%',
    '& .MuiOutlinedInput-root': {
        color: theme.palette.secondary.contrastText,
        fontSize: mediumFont,
        lineHeight: '20px',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
            borderWidth: '1px'
        }
    }
}));

type Props = {
    data?: Patient;
    onClose: () => void;
};

const EditPatientDetails = ({ onClose, data }: Props) => {
    const [updatePatient] = useUpdatePatientMutation();
    const patientcode = useRef<HTMLInputElement>();
    const sureName = useRef<HTMLInputElement>();
    const givenName = useRef<HTMLInputElement>();
    const diagnose = useRef<HTMLInputElement>();
    const therapyGoals = useRef<HTMLInputElement>();
    const therapyHints = useRef<HTMLInputElement>();

    const savePatient = () => {
        if (isEmpty(patientcode.current?.value)) {
            enqueueSnackbar(ErrorMessage(t('no-patient-code-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        const request = {
            id: data?.id,
            patientCode: patientcode.current?.value ?? '',
            sureName: sureName.current?.value,
            givenName: givenName.current?.value,
            diagnose: diagnose.current?.value,
            therapyGoals: therapyGoals.current?.value,
            therapyHints: therapyHints.current?.value
        };

        updatePatient(request).then((result) => {
            if ('error' in result) {
                return;
            }
            onClose();
        });

    };

    return (<>{data && <>
        <Stack pt="20px" sx={{ fontSize: largeFont }} >
            <Grid container>
                <Grid container item direction="column" spacing={1}>
                    <Grid item container>
                        <Grid item fontWeight="bold" xs={4}>{t('cloud-member-form-sure-name-label')}</Grid>
                        <Grid item fontWeight="bold" xs={4}><div style={{ paddingLeft: '10px' }} >{t('cloud-member-form-given-name-label')}</div></Grid>
                        <Grid item fontWeight="bold" xs={4}><div style={{ paddingLeft: '10px' }} >{t('cloud-member-list-patient-code-header')}*</div></Grid>
                        <Grid item xs={4} pt="10px">
                            <StyledTextField inputRef={sureName} defaultValue={data.sureName} sx={{ paddingRight: '10px', width: '100%' }} size="small" />
                        </Grid>
                        <Grid item xs={4} pt="10px">
                            <StyledTextField inputRef={givenName} defaultValue={data.givenName} sx={{ paddingRight: '10px', paddingLeft: '10px', width: '100%' }} size="small" />
                        </Grid>
                        <Grid item xs={4} pt="10px">
                            <StyledTextField data-testid="patientcode" inputRef={patientcode} defaultValue={data.patientCode} sx={{ paddingLeft: '10px', width: '100%' }} size="small" />
                        </Grid>
                    </Grid>

                    <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                    <Grid item fontWeight="bold">{t('patient-diagnose-label')}</Grid>
                    <Grid item>
                        <MultilineField
                            inputRef={diagnose}
                            defaultValue={data.diagnose}
                            multiline
                            fullWidth rows={2} size="small" /></Grid>

                    <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                    <Grid item fontWeight="bold">Therapieziele</Grid>
                    <Grid item>
                        <MultilineField
                            inputRef={therapyGoals}
                            defaultValue={data.therapyGoals}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            rows={2} size="small" /></Grid>

                    <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                    <Grid item fontWeight="bold">{t('edit-patient-therapy-hint-label')}</Grid>
                    <Grid item>
                        <MultilineField
                            inputRef={therapyHints}
                            defaultValue={data.therapyHints}
                            multiline
                            fullWidth
                            rows={2} size="small" />
                    </Grid>
                </Grid>
            </Grid>
        </Stack >


        <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
            <Grid container item pt="10px" pb="10px">
                <Grid item xs={6} pr="10px">
                    <TheraButton onClick={() => onClose()} sx={{ width: '200px' }} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                </Grid>
                <Grid item xs={6} pl="10px" textAlign={'right'}>
                    <TheraButton onClick={() => { savePatient(); }} sx={{ width: '200px' }} size="medium" color="darkgreen" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                </Grid>
            </Grid>
            <Divider sx={{ marginBottom: '15px' }} />

            <Grid container>
                <Grid item xs={6}>
                    <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                </Grid>
                <Grid item xs={6}>
                    <FooterText>{dateString(data.lastUpdated)}</FooterText>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <FooterText>{dateString(data.created)}</FooterText>
                </Grid>
            </Grid>
        </Stack>
    </>
    }</>);

};

export default EditPatientDetails;
