import { Divider, Grid, Stack } from "@mui/material";
import FooterText from "../../../StyledTextField/FooterText";
import { Patient } from "../../../../types/types";
import { t } from "i18next";
import { largeFont } from "../../../../assets/theme";
import { transformTextToMultiline } from "../../../../utils/htmlUtils";
import { dateString } from "../../../ResultAssignment/fragments/SessionDetailsBox";

type Props = {
    data?: Patient;
};
const ShowPatientDetails = ({ data }: Props) => {

    return <>{data && <>
        <Stack pt="10px" sx={{ fontSize: largeFont }} >

            <Grid container>
                <Grid container item direction="column" spacing={1}>
                    <Grid item fontWeight="bold">{t('patient-diagnose-label')}</Grid>
                    <Grid item>{data.diagnose ? transformTextToMultiline(data.diagnose) : '-'}</Grid>
                    <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                    <Grid item fontWeight="bold">Therapieziele</Grid>
                    <Grid item>{data.therapyGoals ? transformTextToMultiline(data.therapyGoals) : '-'}</Grid>
                    <Grid item><Divider sx={{ height: '10px' }} /></Grid>

                    <Grid item fontWeight="bold">Therapiehinweise</Grid>
                    <Grid item>{data.therapyHints ? transformTextToMultiline(data.therapyHints) : '-'}</Grid>
                </Grid>
            </Grid>
        </Stack >


        <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
            <Divider sx={{ marginBottom: '15px' }} />

            <Grid container>
                <Grid item xs={6}>
                    <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                </Grid>
                <Grid item xs={6}>
                    <FooterText>{dateString(data.lastUpdated)}</FooterText>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <FooterText>{dateString(data.created)}</FooterText>
                </Grid>
            </Grid>
        </Stack>
    </>
    }</>;

};

export default ShowPatientDetails;
