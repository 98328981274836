import { AccordionDetails, Box, Dialog, Grid2 as Grid, Slider, Stack, Typography, styled } from "@mui/material";
import TheraButton from "../../TheraButtons/TheraButton";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import theme, { smallFont } from "../../../assets/theme";
import StyledMultilineField from "../../StyledTextField/StyledMultilineField";
import { useUpdateTherapySessionDataMutation } from "../../../api/therapySessionsApi";
import SmilyBlue from "../../../icons/Smilies/SmilyBlue";
import SmilyGreen from "../../../icons/Smilies/SmilyGreen";
import SmilyYellow from "../../../icons/Smilies/SmilyYellow";
import SmilyRed from "../../../icons/Smilies/SmilyRed";
import SmilyGray from "../../../icons/Smilies/SmilyGray";
import SmilyBlueActive from "../../../icons/Smilies/SmilyBlueActive";
import SmilyGrayActive from "../../../icons/Smilies/SmilyGrayActive";
import SmilyGreenActive from "../../../icons/Smilies/SmilyGreenActive";
import SmilyYellowActive from "../../../icons/Smilies/SmilyYellowActive";
import SmilyRedActive from "../../../icons/Smilies/SmilyRedActive";
import { MappedTherapySessionData } from "../../../types/types";
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from "../../../store";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import StyledTextField from "../../StyledTextField/StyledTextField";
import { addHistoryAction } from "../../../store/historyState";
import { getDeviceKey, getLabelField, getUnitField } from "./SessionDetailsBox";
import { t } from "i18next";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    '& .MuiAccordionSummary-root': {
        padding: '0px 0px 0px 0px',
    },
    '& .MuiAccordionDetails-root': {
        padding: '0px 0px 0px 0px',
    },
    '&:first-of-type': {
        borderTop: `1px solid  ${theme.palette.linkgray.main}`,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.darkgreen.main,
    height: 3,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 22,
        width: 22,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            '@media (hover: none)': {
                boxShadow: 'none',
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: smallFont,
        fontWeight: 'bold',
        top: 3,
        backgroundColor: 'unset',
        color: theme.palette.darkgreen.main,
        '&::before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
        },
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
}));

type Props = {
    patientId: string;
    onClose: () => void;
    session: MappedTherapySessionData;
    assignedSessions?: MappedTherapySessionData[];

};

const AssignDialog = ({ session, assignedSessions, onClose, patientId }: Props) => {
    const [updateThreapySession] = useUpdateTherapySessionDataMutation();

    const [tlResult5, setTlResult5] = useState(0);
    const [tlResult6, setTlResult6] = useState(4);

    const commentRef = useRef<HTMLInputElement>();
    const trainingsGoalRef = useRef<HTMLInputElement>();
    const handrailHeightRef = useRef<HTMLInputElement>();
    const beltSizeRef = useRef<HTMLInputElement>();

    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState(true);
    const perceptionValues: string[] = t('perception-of-load', { returnObjects: true });

    const findLastSessionForType = useCallback(() => {
        if (!assignedSessions || assignedSessions.length === 0) {
            return undefined;
        };
        const sessions = assignedSessions?.filter(s => getDeviceKey(s) === getDeviceKey(session));
        if (sessions && sessions.length > 0) {
            return sessions[0];
        }
        return undefined;
    }, [assignedSessions, session]);

    useEffect(() => {
        const ls = findLastSessionForType();
        if (ls) {
            setTlResult5(parseInt(ls.tlResult5 ?? '0'));
            if (handrailHeightRef?.current) {
                handrailHeightRef.current.value = ls.tlResult8 ?? '';
            }
            setExpanded(false);
        }
    }, [findLastSessionForType, session, assignedSessions, handrailHeightRef]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setTlResult5(() => newValue as number);
    };

    const incrementWeight = () => {
        setTlResult5(relief => relief < 60 ? relief + 5 : relief);
    };

    const decrementWeight = () => {
        setTlResult5(relief => relief > 0 ? relief - 5 : relief);
    };

    const saveAndAssign = () => {
        const request = {
            sessionId: session.id,
            patientId,
            comment: commentRef.current?.value,
            trainingsGoal: trainingsGoalRef.current?.value,
            tlResult5: tlResult5.toString(),
            tlResult6: tlResult6.toString(),
            tlResult7: beltSizeRef.current?.value,
            tlResult8: handrailHeightRef.current?.value
        };

        updateThreapySession(request).then(() => {
            dispatch(addHistoryAction({ patientId, sessionId: session.id, action: 'assign' }));
            onClose();
        });

    };

    const getPerceptionOfLoadIcon = (load: number) => {
        switch (load) {
            case 0:
                if (tlResult6 === 0) {
                    return <SmilyBlueActive sx={{ fontSize: '40px' }} />;
                }
                return <SmilyBlue onClick={() => setTlResult6(0)} sx={{ fontSize: '40px' }} />;
            case 1:
                if (tlResult6 === 1) {
                    return <SmilyGreenActive sx={{ fontSize: '40px' }} />;
                }
                return <SmilyGreen onClick={() => setTlResult6(1)} sx={{ fontSize: '40px' }} />;
            case 2:
                if (tlResult6 === 2) {
                    return <SmilyYellowActive sx={{ fontSize: '40px' }} />;
                }
                return <SmilyYellow onClick={() => setTlResult6(2)} sx={{ fontSize: '40px' }} />;
            case 3:
                if (tlResult6 === 3) {
                    return <SmilyRedActive sx={{ fontSize: '40px' }} />;
                }
                return <SmilyRed onClick={() => setTlResult6(3)} sx={{ fontSize: '40px' }} />;
            case 4:
                if (tlResult6 === 4) {
                    return <SmilyGrayActive sx={{ fontSize: '40px' }} />;
                }
                return <SmilyGray onClick={() => setTlResult6(4)} sx={{ fontSize: '40px' }} />;
        }
    };

    const limitHandrailFieldLength = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value.length > 1) {
            event.currentTarget.value = event.currentTarget.value[event.currentTarget.value.length - 1];
        }
    };

    return <Dialog onClose={() => { onClose(); }} open={true}>
        <Box sx={{ width: '480px', padding: '20px', height: '100%', overflow: 'auto' }}>
            <Grid container direction="column">
                <Grid container size={12}>
                    <Grid size={11} fontWeight="bold">{t('assign-dialog-label-1')}</Grid>
                    <Grid size={1} textAlign={"end"} sx={{ cursor: 'pointer' }} onClick={() => onClose()}><CloseIcon stroke={theme.palette.linkgray.main} /></Grid>
                </Grid>
                <Grid ><hr /></Grid>
                <Grid fontWeight="bold">{t('assign-dialog-label-2')}</Grid>
                <Grid container size={12} pt="10px" alignItems="stretch" direction="row">
                    <Grid container size={2.4} direction="column" sx={{ cursor: 'pointer' }}>
                        <Grid textAlign="center">{getPerceptionOfLoadIcon(0)}</Grid>
                        <Grid textAlign="center" fontWeight={tlResult6 === 0 ? 'bold' : 'normal'}>{perceptionValues[0]}</Grid>
                    </Grid>
                    <Grid container size={2.4} direction="column" sx={{ cursor: 'pointer' }}>
                        <Grid textAlign="center">{getPerceptionOfLoadIcon(1)}</Grid>
                        <Grid textAlign="center" fontWeight={tlResult6 === 1 ? 'bold' : 'normal'}>{perceptionValues[1]}</Grid>
                    </Grid>
                    <Grid container size={2.4} direction="column" sx={{ cursor: 'pointer' }}>
                        <Grid textAlign="center">{getPerceptionOfLoadIcon(2)}</Grid>
                        <Grid textAlign="center" fontWeight={tlResult6 === 2 ? 'bold' : 'normal'}>{perceptionValues[2]}</Grid>
                    </Grid>
                    <Grid container size={2.4} direction="column" sx={{ cursor: 'pointer' }}>
                        <Grid textAlign="center">{getPerceptionOfLoadIcon(3)}</Grid>
                        <Grid textAlign="center" fontWeight={tlResult6 === 3 ? 'bold' : 'normal'}>{perceptionValues[3]}</Grid>
                    </Grid>
                    <Grid container size={2.4} direction="column" sx={{ cursor: 'pointer' }}>
                        <Grid textAlign="center">{getPerceptionOfLoadIcon(4)}</Grid>
                        <Grid textAlign="center" fontWeight={tlResult6 === 4 ? 'bold' : 'normal'}>{perceptionValues[4]}</Grid>
                    </Grid>
                </Grid>
                <Grid fontWeight="bold" pt="20px">{t('assign-dialog-label-3')}</Grid>
                <Grid pt="20px">
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <TheraButton onClick={decrementWeight} style={{ width: '40px', paddingLeft: '0px', paddingRight: '0px' }} color="lightgreen">-</TheraButton>
                        <div style={{ color: theme.palette.linkgray.main }}>0</div>
                        <StyledSlider step={5} defaultValue={30} min={0} max={60} size="small" value={tlResult5} onChange={handleSliderChange} valueLabelDisplay="on" />
                        <div style={{ color: theme.palette.linkgray.main }}>60</div>
                        <TheraButton onClick={incrementWeight} style={{ width: '40px', paddingLeft: '0px', paddingRight: '0px' }} color="lightgreen">+</TheraButton>
                    </Stack>
                </Grid>
                <Grid fontWeight="bold" pt="20px">{t('assign-dialog-label-4')}</Grid>
                <Grid  >
                    <StyledMultilineField
                        variant="outlined"
                        color="primary"
                        multiline
                        rows={3}
                        inputRef={commentRef}
                        defaultValue={session ? session.comment : ''}
                    />
                </Grid>

                <Grid pt="20px">
                    <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
                        <AccordionSummary id="additional-fields">
                            <Typography fontWeight="bold" >{t('assign-dialog-label-5')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container sx={{}}>
                                <Grid size={6}>{session[getLabelField('tlResult8')]} ({session[getUnitField('tlResult8')]})</Grid>
                                <Grid size={6} sx={{ paddingLeft: '10px' }}>{session[getLabelField('tlResult7')]}</Grid>
                                <Grid size={6}>
                                    <StyledTextField type="number"
                                        onKeyDown={(event) => {
                                            if (!/[1-7]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        slotProps={{
                                            htmlInput: { maxLength: 1 },
                                            input: {
                                                inputProps: { max: 7, min: 1 }
                                            }
                                        }}
                                        onChange={limitHandrailFieldLength}
                                        defaultValue={findLastSessionForType()?.tlResult8 ?? ''}
                                        inputRef={handrailHeightRef}
                                        sx={{ paddingRight: '10px' }}></StyledTextField>
                                </Grid>
                                <Grid size={6}>
                                    <StyledTextField
                                        defaultValue={findLastSessionForType()?.tlResult7 ?? ''}
                                        inputRef={beltSizeRef} sx={{ paddingLeft: '10px' }}></StyledTextField>
                                </Grid>
                                <Grid size={12} pt="20px">{t('assign-dialog-trainings-goal')}</Grid>
                                <Grid size={12}>
                                    <StyledMultilineField
                                        variant="outlined"
                                        color="primary"
                                        multiline
                                        rows={3}
                                        inputRef={trainingsGoalRef}
                                        defaultValue={findLastSessionForType()?.trainingsGoal ?? ''}
                                    />
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid container pt="40px">
                    <Grid size={6} pr="10px">
                        <TheraButton onClick={onClose} fullWidth size="medium" color="lightgreen" variant="contained">Verwerfen</TheraButton>
                    </Grid>
                    <Grid size={6} pl="10px">
                        <TheraButton onClick={saveAndAssign} fullWidth size="medium" color="darkgreen" variant="contained">Speichern</TheraButton>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </Dialog >;
};

export default AssignDialog;
