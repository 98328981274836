import TwoColumns from "../../../PageTemplates/TwoColumns";
import PatientDetails from "./PatientDetails";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectCurrentPatientId } from "../../../../store/uiState";
import { useFetchDeviceListMutation } from "../../../../api/deviceApi";
import { useEffect, useState } from "react";
import { DeviceMessage } from "../../../../types/types";
import AssignedSessions from "../AssignedSessions";
import SessionsListButtonBar from "../SessionlistFilterHeader/SessionsListButtonBar";
import { addAllDevicesFilter } from "../../../../store/filterState";
import useFetchAssignedSessions from "../../../../hooks/useFetchAssignedSessions";
import { styled } from "@mui/material";
const StyledDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 100px)'
});

const PatientInfo = () => {
    const patientId = useAppSelector(selectCurrentPatientId);
    const { isAssignedSessionsLoading, assignedSessions } = useFetchAssignedSessions(patientId ?? '');
    const [getDeviceList] = useFetchDeviceListMutation();
    const [devices, setDevices] = useState<DeviceMessage[]>();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (assignedSessions) {
            dispatch(addAllDevicesFilter(['all']));
        }
    }, [assignedSessions, dispatch]);

    useEffect(() => {
        getDeviceList().then((result) => {
            if ('data' in result) {
                if (JSON.stringify(result.data) !== JSON.stringify(devices)) {
                    setDevices(() => result.data);
                }
            }
        });
    }, [devices, getDeviceList]);

    if (isAssignedSessionsLoading) {
        return <div>loading...</div>;
    }

    const getLeftComponent = () => {
        if (patientId) {
            return <PatientDetails patientId={patientId} />;
        }
    };

    const getRightComponent = () => {
        return (
            <>
                <SessionsListButtonBar devices={devices || []} assignedSessions={assignedSessions || []} />
                <StyledDiv>
                    <AssignedSessions assignedSessions={assignedSessions || []} />
                </StyledDiv >
            </>
        );
    };

    return (
        <TwoColumns leftWidth="67%" rightWidth="33%"
            leftComponent={getLeftComponent()}
            rightComponent={getRightComponent()}
        />
    );

};

export default PatientInfo;
